import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from './images/logo.png';
import './Navbar.css';

const Navbar = () => {
    const [click, setClick] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const handleClick = () => setClick(!click);
    const closeMenu = () => setClick(false);

    return (
        <div className={`header ${!visible ? 'hidden' : ''}`}>
            <nav className='navbar'>
                <a href='/' className='logo'>
                    <img src={logo} alt='logo' />
                </a>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (
                        <FaTimes size={30} style={{ color: '#ffffff' }} />
                    ) : (
                        <FaBars size={30} style={{ color: '#ffffff' }} />
                    )}
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <a href='/' onClick={closeMenu}>
                            O Nas
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#offer' onClick={closeMenu}>
                            Oferta
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#portfolio' onClick={closeMenu}>
                            Realizacje
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#materials' onClick={closeMenu}>
                            Materiały
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#contact' onClick={closeMenu}>
                            Kontakt
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import kitchen from './images/design.jpg';
import facebook from './images/facebook.png';
import instagram from './images/instagram.png';

import './About.css';

const About = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className={`about ${isVisible ? 'fadeIn' : ''}`} id='about'>
            <div className='container'>
                <img src={kitchen} alt='kitchen' className={`col-1 ${isVisible ? 'fadeIn' : ''}`} />
                
                <div className={`col-2 ${isVisible ? 'fadeIn' : ''}`}>
                    <h2>O Nas</h2>
                    <span className='line'></span>
                    <p>Nasza firma, Meble Gajdowski, to gwarancja doskonałej jakości i perfekcji w każdym detalu. Działamy z pasją i zaangażowaniem już 50 lat, wykorzystując najnowocześniejsze technologie i najlepsze maszyny stolarskie, aby stworzyć dla Ciebie nie tylko kuchnię, ale prawdziwe arcydzieło. Tworzymy przestrzenie, w których komfort spotyka się z elegancją, a funkcjonalność z estetyką.</p>
                    <p>Pozwól nam spełnić Twoje marzenia o idealnej kuchni. Zaufaj naszemu doświadczeniu i oddaj swoje pomysły w ręce mistrzów rzemiosła. Razem stworzymy niepowtarzalne miejsce, w którym każdy detal odzwierciedla Twoje osobiste preferencje i styl życia.</p>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=61552285086938" target="_blank" rel="noopener noreferrer">
                            <img src={facebook} alt='facebook icon'/>
                        </a>
                        <a href="https://www.instagram.com/gajdowski.schody/?fbclid=IwAR0_q5kx1yR2VOMMyNHFsvWynv-HctpceX5SwpW0U8efFlOCDQUTFP0t8SUl" target="_blank" rel="noopener noreferrer">
                            <img src={instagram} alt='instagram icon'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;

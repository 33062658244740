import React from 'react';
import './Materials.css'; 
import glossyFront from './images/glossyKitchen.jpg';
import matteFront from './images/matKitchen.jpg';
import boardFront from './images/boardKitchen.jpg';
import veneerFront from './images/veneerKitchen.jpg';

const Materials = () => {
  return (
    <div className='materials' id='materials'>
      <h2 className='materials-heading'>Dostępne materiały</h2>
      <span className='line'></span>
      <ul className='materials-list'>
        <li>
          <div className='material-container'>
            <strong>Fronty w połysku</strong>
            <img src={glossyFront} alt='Glossy Front' className='material-image' />
            <p>Wybierz ten materiał, jeśli szukasz efektownego, nowoczesnego wykończenia. Dostępne w szerokiej gamie kolorów z palety RAL.</p>
          </div>
        </li>
        <li>
          <div className='material-container'>
            <strong>Fronty w macie</strong>
            <img src={matteFront} alt='Matte Front' className='material-image' />
            <p>Eleganckie i subtelne wykończenie, które dodaje wnętrzu wyrafinowanego charakteru. Dostępne w różnych kolorach z palety RAL.</p>
          </div>
        </li>
        <li>
          <div className='material-container'>
            <strong>Fronty z płyty meblowej</strong>
            <img src={boardFront} alt='Board Front' className='material-image' />
            <p>Trwały i łatwy w utrzymaniu materiał, który pozwoli Ci stworzyć meble o różnorodnych wzorach i kształtach. Dostępne z firm EGGER, SWISS KRONO lub KRONOSPAN.</p>
          </div>
        </li>
        <li>
          <div className='material-container'>
            <strong>Fronty z forniru</strong>
            <img src={veneerFront} alt='Veneer Front' className='material-image' />
            <p>Naturalny materiał z drewna, który dodaje ciepła i charakteru Twoim wnętrzom. Wybierz ten materiał, jeśli cenisz naturalny wygląd i trwałość.</p>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Materials;

import React from 'react'
import Navbar from './components/Navbar'
import About from './components/About'
import Offer from './components/Offer'
import Portfolio from './components/Portfolio'
import Materials from './components/Materials'
import Contact from './components/Contact'

function App() {
  return (
    <div>
      <Navbar/>
      <About/>
      <Offer/>
      <Portfolio/>
      <Materials/>
      <Contact/>
    </div>
  );
}

export default App;
import React from 'react';
import { GoCheck } from "react-icons/go";
import kitchen from './imagesOffer/kitchen.png';
import office from './imagesOffer/office-desk.png';
import livingRoom from './imagesOffer/television.png';
import wardrobe from './imagesOffer/closet.png';
import bathroom from './imagesOffer/sink.png';

import './Offer.css';

const Offer = () => {
  return (
    <div className='offer' id='offer'>
      <h2>Nasza oferta</h2>
      <span className='line'></span>
      <ul>
        <li>
          <p><GoCheck size={24} /> Meble kuchenne </p>
          <img src={kitchen} alt='Kitchen' className='offer-image' />
        </li>
        <li>
          <p><GoCheck size={24} /> Meble biurowe</p>
          <img src={office} alt='Office' className='offer-image' />
        </li>
        <li>
          <p><GoCheck size={24} /> Meble do salonu </p>
          <img src={livingRoom} alt='Living room' className='offer-image' />
        </li>
        <li>
          <p><GoCheck size={24} /> Garderoby </p>
          <img src={wardrobe} alt='Wardrobe' className='offer-image' />
        </li>
        <li>
          <p><GoCheck size={24} /> Meble do łazienek </p>
          <img src={bathroom} alt='Bathroom' className='offer-image' />
        </li>
      </ul>
    </div>
  );
}

export default Offer;

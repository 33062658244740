import React from 'react';
import './Portfolio.css'

import img1 from './imagesGallery/image1.jpg';
import img2 from './imagesGallery/image2.jpg';
import img3 from './imagesGallery/image3.jpg';
import img4 from './imagesGallery/image4.jpg';
import img5 from './imagesGallery/image5.jpg';
import img6 from './imagesGallery/image6.jpg';


const Portfolio = () => {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
  ];

  return (
    <div className='portfolio' id='portfolio'>
      <h2>Galeria</h2>
      <span className='line'></span>
      <div className='image-grid'>
        {images.map((image, index) => (
          <img key={index} src={image} alt="" />
        ))}
      </div>
    </div>
  );
}

export default Portfolio;

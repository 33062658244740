import React from 'react';
import './Contact.css'; 

const Contact = () => {
  const phoneNumber = '+48 604 596 431';
    
  const handleCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className='contact' id='contact'>
      <h2>Kontakt</h2>
      <span className='line'></span>
      <div className='contact-info'>
        <div className='contact-item'>
          <h3>Adres:</h3>
          <p>Miasto: Pokrzydowo 152</p>
          <p>Kod pocztowy: 87-312</p>
          </div>
            <div className='contact-item'>
                <h3>Telefon:</h3>
                <p>{phoneNumber}</p>
                <button className="call-btn" onClick={handleCall}>Zadzwoń</button>
            </div>
            <div className='contact-item'>
                <h3>Email:</h3>
                <p>gajdowskischody@o2.pl</p>
                <p>krzysztofgajdowski.furniture@gmail.com</p>
            </div>
            <div className='contact-item'>
                <h3>Lokalizacja:</h3>
                <iframe
                    title="Lokalizacja"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.019175882184!2d18.90389621564686!3d53.15968397986279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fbfacc6b9edf43%3A0xa550f8b1ad9a6b22!2sPokrzydowo%20152%2C%2087-312%20Pokrzydowo!5e0!3m2!1sen!2spl!4v1646519262320!5m2!1sen!2spl"                    width="300"
                    height="200"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
        </div>
    </div>
  );
}

export default Contact;
